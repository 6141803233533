"use strict";

var _interopRequireDefault = require("D:/nodeApp/hj-aadmin-vue-new/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _app = _interopRequireDefault(require("./modules/app"));
var _permission = _interopRequireDefault(require("./modules/permission"));
var _user = _interopRequireDefault(require("./modules/user"));
var _tagsView = _interopRequireDefault(require("./modules/tagsView"));
var _getters = _interopRequireDefault(require("./getters"));
_vue.default.use(_vuex.default);
var store = new _vuex.default.Store({
  modules: {
    app: _app.default,
    permission: _permission.default,
    user: _user.default,
    tagsView: _tagsView.default
  },
  getters: _getters.default
  // plugins: [plugin]
});
var _default = exports.default = store;