import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import Layout from '@/layout';
import { getUserPermission } from '@/api/login';
import { setPermissions } from '@/libs/auth';
import { getJsonTree } from '@/libs/tree';

// https://juejin.cn/post/6844904082050646023
var loadView = function loadView(viewPath) {
  // 用字符串模板实现动态 import 从而实现路由懒加载,不推荐,并且VUE_CLI_BABEL_TRANSPILE_MODULES为true时失效
  // https://github.com/PanJiaChen/vue-element-admin/issues/2075
  // return () => import(`@/views/${viewPath}.vue`)
  return function (resolve) {
    return require(["@/views/".concat(viewPath, ".vue")], resolve);
  };
};
var filterAsyncRouter = function filterAsyncRouter(routeList) {
  // 权限已经后台过滤了
  return routeList.filter(function (route) {
    if (route.component === 'layout') {
      // 如果 layout = layout 说明是布局组件,将真正的布局组件赋值给它
      route.component = Layout;
    } else {
      // 如果不是布局组件就只能是页面的引用了
      // 利用懒加载函数将实际页面赋值给它
      route.component = loadView(route.component);
    }
    var meta = {};
    if (route.icon) {
      meta.icon = route.icon;
      delete route.icon;
    }
    if (route.title) {
      meta.title = route.title;
      delete route.title;
    }
    if (route.no_cache) {
      meta.noCache = !route.no_cache;
      delete route.no_cache;
    }
    if (route.breadcrumb) {
      meta.breadcrumb = !!route.breadcrumb;
      delete route.breadcrumb;
    }
    if (route.affix) {
      meta.affix = !!route.affix;
      delete route.affix;
    }
    // 路由名,对应组件名
    route.name = route.route_name;
    delete route.route_name;
    route.meta = meta;
    // 判断是否存在子路由，并递归调用自己
    if (route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
};
var state = {
  permissions: [],
  menus: []
};
var getters = {
  permissions: function permissions(state) {
    return state.permissions;
  },
  menus: function menus(state) {
    return state.menus;
  }
};
var mutations = {
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
    setPermissions(permissions);
    state.permissions = permissions;
  },
  SET_MENUS: function SET_MENUS(state, menus) {
    // setMenus(menus)
    // 再用路由解析函数解析 List 为真正的路由列表
    state.menus = filterAsyncRouter(menus);
    // 修改路由获取状态
    state.hasGetRoute = true;
  }
};
var actions = {
  loadPermissions: function loadPermissions(_ref) {
    var commit = _ref.commit;
    return new Promise(function (resolve, reject) {
      getUserPermission().then(function (res) {
        // TODO: 判断数据正确性
        var data = res.data;
        var permissions = data.map(function (item) {
          return item.name;
        });
        data.unshift({
          id: null,
          affix: 1,
          'breadcrumb': 1,
          component: 'admin/dashboard/index',
          icon: 'star',
          name: 'Dashboard',
          no_cache: 0,
          parent_id: 0,
          path: '/dashboard',
          sort: 1,
          title: 'dashboard'
        });
        var menu = getJsonTree(data, 0);
        commit('SET_MENUS', menu);
        commit('SET_PERMISSIONS', permissions);
        resolve(true);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  resetPermissions: function resetPermissions(_ref2) {
    var commit = _ref2.commit;
    commit('SET_PERMISSIONS', []);
  }
};
export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  actions: actions
};