import "core-js/modules/es.array.concat.js";
import defaultSettings from '@/settings';
import VueI18n from '@/lang';
var title = defaultSettings.title || 'Agent Admin';
export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    pageTitle = VueI18n.tc("meta.title.".concat(pageTitle));
    return "".concat(pageTitle, " - ").concat(title);
  }
  return "".concat(title);
}

// translate router.meta.title, be used in breadcrumb sidebar tagsview
export function generateTitle(title) {
  var hasKey = this.$te('meta.title.' + title);
  if (hasKey) {
    // $t :this method from vue-i18n, inject in @/lang/index.js
    var translatedTitle = this.$t('meta.title.' + title);
    return translatedTitle;
  }
  return title;
}