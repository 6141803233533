import "core-js/modules/es.array.push.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
// 默认parentId = 0， 离散的数据会被过滤
export function getJsonTree(data, parentId) {
  var key = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'parent_id';
  var itemArr = [];
  for (var i = 0; i < data.length; i++) {
    var node = data[i];
    if (node[key] === parentId) {
      // 当会放到下级时，可以从原始数组挪出， 但是这边会造成很多问题，直接splice删除，循环的i会出错
      // 简单深拷贝,保证不影响原始数据
      var newNode = JSON.parse(JSON.stringify(node));
      var childrens = getJsonTree(data, node.id, key);
      // 如果没有下级，不添加children属性
      if (childrens.length > 0) {
        newNode.children = childrens;
      }
      itemArr.push(newNode);
    }
  }
  return itemArr;
}
export function getJsonTree1(data) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'pid';
  data.forEach(function (item) {
    delete item.children; // 删除 所有 children,以防止多次调用
  });
  // 将数据存储为 以 id 为 KEY 的 map 索引数据列
  var map = {};
  data.forEach(function (item) {
    map[item.id] = item;
  });
  var val = [];
  data.forEach(function (item) {
    var parent = map[item[key]];
    if (parent) {
      (parent.children || (parent.children = [])).push(item);
    } else {
      val.push(item);
    }
  });
  return val;
}
// 还有一种思路，大循环只要一次，从第一个循环，每次直接把循环的那个值直接挂在在他的上级，顶级时直接退出，非顶级找上级