export default {
  meta: {
    title: {
      dashboard: 'Dashboard',
      system: 'System',
      role: 'Role',
      permission: 'Permission',
      webSetting: 'Web Setting',
      notice: 'Announcement',
      userManager: 'User',
      onlineUser: 'Online User',
      game: 'Game',
      betOrders: 'Game Record',
      unSettleOrder: 'Unsettle Orders',
      roundInfos: 'Round Infos',
      report: 'Game Report',
      loggerManager: 'Log',
      riskManager: 'Risk Manager',
      rootRecharge: 'Root Recharge',
      rootUpdate: 'Root Update',
      blockIps: 'Block Ips',
      userPolicies: 'User Policies',
      trade: 'Trade',
      rechargeAccount: 'Recharge Account',
      deposit: 'Deposit',
      withdraw: 'Withdrawal',
      transaction: 'Transaction List',
      joinUs: 'Join Us',
      reportStats: 'Report Statistics'
    }
  },
  common: {
    role: 'Role',
    login: 'Login',
    reset: 'Reset',
    logout: 'Logout',
    dragonTiger: 'Dragon/Tiger',
    baccarat: 'Baccarat',
    title: 'Title',
    desc: 'Desc',
    status: 'Status',
    currency: 'Currency',
    user: 'User',
    agent: 'Agent',
    member: 'Member',
    subAccount: 'Sub-account',
    open: 'Open',
    close: 'Close',
    all: 'All',
    bet: 'Bet',
    permission: 'Permission',
    account: 'Account',
    childrenAccount: 'Child Account',
    logger: 'Log',
    createdTime: 'Creation Time',
    gameRecord: 'Game Record',
    type: 'Type',
    remark: 'Remarks',
    startTime: 'Start Time',
    endTime: 'End Time',
    clearCondition: 'Clear Condition',
    fuzzy: 'Fuzzy Search',
    exactly: 'Exact Search',
    lastweek: 'Last Week',
    yesterday: 'Yesterday',
    today: 'Today',
    thisweek: 'This Week',
    thismonth: 'This Month',
    keyword: 'Keyword',
    detail: 'Details',
    tables: 'Restricted Table Login',
    bpNiuniu: 'Zhuangxian Niuniu',
    niuNiu: 'Bull Bull',
    bpTongzi: 'Zhuangxian Tongzi',
    tongzi: 'Zongzi',
    upPassWord: 'Your password has been used for more than a month, please change it immediately',
    name: 'Name',
    email: 'Email',
    search: 'Search',
    createdBy: 'Created by',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    actions: 'Action',
    edit: 'Edit ',
    delete: 'Delete',
    add: 'Add ',
    username: 'Username',
    password: 'Password',
    captcha: 'Captcha',
    ruleMsg: {
      require: ' is required!',
      validatePassword: 'Please input password',
      passwordPattern: 'The password cannot be less than 6 characters and contains uppercase letters, lowercase letters, numbers, and special symbols.',
      confirmPassword: 'The password is not match!'
    },
    oldPassword: 'Old Password',
    confirmPassword: 'Confirm Password',
    confirm: 'Confirm',
    cancel: 'Cancel',
    success: 'Success',
    fail: 'Fail',
    createSuccess: 'Created successfully!',
    editSuccess: 'Successful editing!',
    deleteSuccess: 'Successful deleted!',
    operateSuccess: 'Successful operation!',
    operateFail: 'Operation Fail!',
    description: 'Description',
    guardName: 'Guard name',
    permissionGroup: 'Permission Group',
    displayName: 'Display name',
    icon: 'Icon',
    sequence: 'Sort',
    level: 'Level',
    router: 'Router',
    parentMenu: 'Parent Menu',
    topMenu: 'Top Menu',
    assignPermission: 'Assign permission',
    assignRole: 'Assign role',
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    changePassword: 'Change Password',
    googleVerify: 'Google Verification',
    sponsorLinks: 'Sponsor Links',
    loginInfo: 'Login Information',
    currentLogUser: 'Currently logged in user',
    alias: 'Alias',
    lastLoginInfo: 'Last login information',
    lastLoginIp: 'Last login IP',
    loginInfoTime: 'Login information for this time',
    nowLoginTime: 'Log in time',
    loginTimes: 'Login times',
    currentLastnews: 'Current lastest news',
    twoFaMobileDesc: 'Open your 2FA mobile app and scan the QR code on the left',
    twoFaMobilesecDesc: "If your 2FA mobile app doesn't support QR barcodes, enter the following numbers:",
    inputVerifyTip: 'Please enter the latest six digits on the validator',
    currencyTip: '*Once the currency type is set, it cannot be modified, and all subordinates are settled according to this currency type. Only the company level has the right to modify the currency type.',
    upperLowerPoint: 'Normal upper and lower points',
    divideToProportion: 'Divide according to proportion',
    rebateTip: '*Rebate proportion setting, the total amount of winning and losing + code required is',
    rebateTip2: 'side to calculate',
    gameType: 'Game Type',
    autoRefresh: 'Auto Refresh',
    totalMoney: 'Total Money',
    depMoney: 'Deposit Money',
    withMoney: 'Withdrawal Money',
    copy: 'Copy',
    download: 'Download',
    last8Days: 'Last 8 days',
    yes: 'Yes',
    no: 'No',
    menu: 'Menu',
    path: 'Path',
    selectIconTip: 'Click the select icon',
    refresh: 'Refresh',
    sort: 'Sort',
    delMessage: 'Are you sure to delete this data?'
  },
  role: {
    name: 'Role Name',
    guard: 'Guard',
    parentRole: 'Parent Role',
    assignPermission: 'Assign Permission',
    ruleMsg: {
      lengthName: 'Name must be between 2 and 32 characters',
      namePattern: 'The name can only contain letters, numbers, and _-'
    }
  },
  permission: {
    title: 'Permission Name',
    menuType: 'Menu Type',
    module: 'Module',
    menu: 'Menu',
    button: 'Button',
    icon: 'Icon',
    selectIconTip: 'Click the select icon',
    isLink: 'Is Link',
    visible: 'Visible',
    menuTitle: 'Menu Name',
    permission: 'Permission Tag',
    path: 'Path',
    componentPath: 'Component Path',
    cache: 'Cache',
    routeName: 'Route Name',
    superCategory: 'Super Menu',
    buttonName: 'Button Name',
    redirect: 'Redirect',
    breadcrumb: 'Breadcrumb',
    affix: 'Affix',
    isCredit: 'Credit',
    ruleMsg: {
      title: 'Please input the menu name or operation name!'
    }
  },
  onlineUser: {
    onlineUser: 'Online User',
    username: 'Username',
    balance: 'Real-time balance',
    lastBet: 'Unsettled bets',
    tableId: 'Table Id',
    winLossState: 'Win/Lose',
    tuserLine: 'Forced offline',
    tuserRoom: 'Kick out of the room',
    tuserIp: 'Ban IP',
    fenghao: 'Ban Hao',
    loginMethod: 'Login Method'
  },
  record: {
    username: 'Username',
    gameId: 'Game Id',
    tableNo: 'Table No',
    betTime: 'Betting Time',
    settleTime: 'Settle Time',
    betDetails: 'Bet Details',
    gameResult: 'Game Result',
    betAmount: 'Bet Amount',
    validBet: 'Valid Bet',
    commission: 'Commission',
    winLose: 'Win/Lose',
    balance: 'Balance',
    platform: 'Platform',
    status: 'Status',
    notSettle: 'Not Settle',
    settled: 'Settled',
    cancelled: 'Cancelled',
    tableShoes: 'Table Shoes',
    limit: 'Limit',
    totalWinlose: 'Total Winlose'
  },
  report: {
    username: 'Username',
    betweenTime: 'Between Time',
    nickname: 'Nickname',
    betCount: 'Bet Count',
    betAmount: 'Bet Amount',
    winLose: 'Win/Lose',
    rolling: 'Rolling',
    rollingRate: 'Rolling Rate (%)',
    rollingAmount: 'Rolling Amount',
    commissionAmount: 'Commission',
    costRate: 'Percentage (%)',
    upperProfit: 'Up Profit',
    lowerProfit: 'Low Profit',
    addPoint: 'Advanced points',
    reducePoint: 'Reduce points',
    balance: 'Balance',
    detail: 'Details',
    agentName: 'Superior Agent',
    memberName: 'Member username',
    backRate: 'Rebate(%)',
    backPoint: 'Rebate payable',
    countRolling: 'Count Rolling',
    countPoint: 'Count Point',
    realWinLos: 'Actual Win/Lose',
    agentComm: 'Agent Commission',
    uCountPoint: 'Total payable',
    back: 'Return to the previous level',
    agentUser: 'Agent Account',
    totalReport: 'Total Report',
    addAndReduce: 'Add/Reduce'
  },
  agent: {
    upperAgent: 'Upper agent',
    agentInfo: 'Agent Information',
    sn: 'Username',
    nickname: 'Nickname',
    status: 'Status',
    phoneNumber: 'Phone',
    point: 'Point',
    sharing: 'Sharing',
    commission: 'Commission ',
    commStatus: 'Comm Status',
    cost_rate: 'Cost Rate',
    rebate_rate: 'Rebate Rate',
    secret: 'Api secret key',
    sign_key: 'Sign Key',
    addChildren: 'Add children',
    betLimit: 'Bet limit',
    totalPoint: 'Remaining points',
    addchildren: 'Add children',
    changePoint: 'Change points',
    todayLimit: "Today's winning limit",
    countLimit: 'Total win limit',
    back_rate: 'Rebate Ratio',
    settleType: 'Settlement Type',
    back_limit: 'Rebate limit',
    agentRelation: 'Agency relationship',
    commType: 'Commission Type',
    agentList: 'Agent List',
    memberList: 'Member List',
    subAccountList: 'Sub-account List',
    comm1: 'All',
    comm2: 'Lose',
    comm3: 'Win',
    registered: 'Registered',
    ruleMsg: {
      require: ' is required!',
      validateSn: 'Must start with a letter, and 0-9 and a-z',
      validateConfirmPw: 'Please enter password',
      confirmPassword: 'The password is not match!',
      validatePoint: 'Points cannot be greater than ',
      validateCostRate: 'The cost rate cannot be greater than ',
      validateRebateRate: 'The rebate rate cannot be larger than ',
      validateBetLimit: 'At least 1 group of agents',
      validateRebateRatio: 'The rebate ratio cannot be greater than ',
      validateChangePoint: 'The points must be greater than 0',
      validateCommission: 'The commission can not be greater than ',
      checkPoint: 'The points cannot bigger than superior',
      checkPoint2: 'The current user does not have enough points for deduction'
    }
  },
  user: {
    username: 'Username',
    nickname: 'Nickname',
    last_login_time: 'Last login time',
    today_money_count: 'Today Money Count',
    bet_status: 'Bet Status',
    loginRemind: 'Online reminder',
    limitRegion: 'Limited region',
    register_time: 'Created Time',
    hideRebate: 'Hide rebate',
    baccCommission: 'Baccarat Commission',
    winLimit: 'Win Limit',
    dtCommission: 'Dragon/Tiger Commission',
    userType: 'User Source',
    firstUser: 'mainnet',
    secUser: 'cashnet',
    thrUser: 'RUPT',
    reUser: 'Unrestriction',
    loginIp: 'Login IP',
    baccCommBanker: 'The system defaults (Zhuangying: the company takes 5%)',
    baccCommBanker2: 'Zhuangying: Rake 10% = Agency Rake 5% + Company Rake 5%',
    baccCommTie: 'Win: rake amount = bet amount',
    baccCommPair: 'Pair: Rake amount = Bet amount',
    dtComm: 'System default (no draw, tie kill)',
    dtComm2: 'Dragon or Tiger: The company draws 3%, and the principal is returned in a tie',
    dtComm3: "Dragon or Tiger: Agent's rake is 2% + company's rake is 3%, and the principal will be returned in a tie",
    dtComm4: 'Dragon or Tiger: The agent draws 5%, and the tie will kill',
    dtComm5: "Dragon or Tiger: Agent's rake is 5% + company's rake is 3%, and the principal will be returned in a tie",
    todayLimit: 'The daily winning limit is optional, leave it blank, if it is 0, there is no limit',
    countLimit: 'The total winning limit is optional, leave it blank, if it is 0, there is no limit'
  },
  editPoint: {
    addOrReduce: 'Add/Reduce',
    add: 'Add dot(+)',
    reduce: 'Reduce point (-)',
    changePoint: 'Change point',
    upperAgent: 'Upper Agent'
  },
  logger: {
    eventGroup: 'Event group',
    event: 'Event',
    username: 'Username',
    operator: 'Operator',
    area: 'Area',
    operationContent: 'Operation Content',
    changePoint: 'Amount',
    balance: 'Balance',
    noLimit: 'No Limit',
    loginLogout: 'Login and logout',
    agentLogin: 'Agent login',
    userLogin: 'User login',
    logout: 'Logout',
    lock: 'Lock',
    unlock: 'Unlock',
    enableBet: 'Enable betting',
    disableBet: 'Disable betting',
    balanceChange: 'Changes in account balance',
    addDot: 'Add dot',
    rootAccplusPoint: 'Root account plus points',
    minusPoint: 'Minus points',
    bet: 'Bet',
    betToWin: 'Bet to win',
    addPointToTop: 'Add points to the top',
    minusLowerPoint: 'Lower points minus points',
    userSettlePlusPoint: 'User settlement plus points',
    agencySettlePlusPoint: 'Agency settlement plus points',
    groupOp: 'User group operations',
    createUserAgent: 'Create user or agent',
    editUser: 'Edit user',
    editAgent: 'Edit agent',
    modifyRootAcc: 'Modify root account',
    addSubPoint: 'Add and subtract points'
  },
  super: {
    event: 'Super user adds points',
    addPoint: 'Add points',
    addRemark: 'Remarks',
    tableNo: 'Table No',
    amount: 'Amount',
    round: 'Round',
    max_counts: 'Max Counts',
    now_counts: 'Now Counts',
    his_counts: 'History Counts',
    ip: 'IP Address'
  },
  currency: {
    rate: 'Exchange rate'
  },
  bank: {
    accountNumber: 'Account number',
    accountType: 'Bank',
    accountName: 'Account name',
    userName: 'Name',
    status: 'Status',
    member: 'Username',
    money: 'Transaction amount',
    transfer: 'Transaction number',
    transferTime: 'Transaction time',
    setTime: 'Set time',
    dBankInfo: 'Deposit Account',
    WbKInfo: 'Withdrawal Account',
    name: 'Name',
    remark: 'Remark',
    phone: 'Phone number',
    wthMoney: 'Transaction amount',
    isTure: 'Operation',
    sussess: 'Pass',
    failed: 'Failed',
    wait: 'Pending review',
    confirmTrue: 'Confirm',
    cancel: 'Cancel',
    info: 'Please click confirm after verifying that the information is correct!',
    deposit: 'Deposit',
    withdraw: 'Payment has been made',
    othername: 'Alias',
    opperson: 'Entry clerk',
    auperson: 'Auditor',
    endperson: 'Cashier',
    sussessAdd: 'Operator information',
    endtime: 'Payment time',
    end: 'Payment',
    waitau: 'Audit',
    print: 'Print',
    settype: 'Upper subtype',
    setMoney: 'Remittance',
    setPoint: 'Point',
    depMoney: 'Deposit',
    withMoney: 'Withdrawal',
    moneyPoint: 'Amount',
    waitPoint: 'Pending order',
    letPoint: 'Order has been paid',
    withSettype: 'Lower subtype',
    sendMoney: 'Payment',
    sendPoint: 'Point',
    desPoint: 'Sign the order',
    setPointName: 'Return Order',
    signPoint: 'Sign',
    transferPoint: 'Settlement',
    firstOp: 'Click to confirm',
    upperLowerType: 'Upper and lower types',
    totalSuccessMoney: 'Total Success Money',
    totalWaitMoney: 'Total Wait Money',
    totalFailMoney: 'Total Fail Money',
    totalRemittance: 'Total Remittance',
    ruleMsg: {
      require: ' is required!',
      checkSn: 'The username is wrong, the user does not exist',
      validateUsername: 'Must start with a letter, and 0-9 and a-z'
    },
    WbKInfoTip: '*(Account opening bank, account name, card number separated by,) Please fill in according to this format.',
    success: 'Success',
    failure: 'Failure',
    pendingOrder: 'Pending order',
    remittance: 'Remittance',
    signCode: 'Sign Rolling',
    signOrder: 'Sign the order',
    settlement: 'Settlement',
    payment: 'Payment',
    returnSignRolling: 'Return Sign Rolling',
    returnSignOrder: 'Return signed order',
    approved: 'Approved',
    returnOrder: 'Payment has been made (order has been returned)'
  },
  join: {
    name: 'Title',
    phone: 'Mobile Phone',
    email: 'Email',
    skype: 'Skype',
    content: 'Message content',
    status: 'Status',
    beContacted: 'To be contacted',
    contacted: 'Contacted'
  },
  notice: {
    notice_name: 'Name',
    op_name: 'Operator',
    notice_type: 'Type',
    content: 'Content',
    status: 'Status',
    agent: 'Agent',
    user: 'Member',
    ruleMsg: {
      require: ' is required!'
    }
  },
  auth: {
    notice: 'Announcement',
    join: 'Join information',
    banks: 'Deposit and Withdrawal',
    userliston: 'Online user operation',
    online: 'Online User',
    bill_index: 'Game Record',
    agent_report: 'Agent Report',
    user_report: 'User Report'
  },
  sys: {
    updateRole: 'Update Role',
    deRole: 'Delete Role',
    permission: 'Permission',
    addPermissions: 'Add permissions',
    manager: 'System',
    permissionGroup: 'Permission Group',
    updatePermission: 'Update Permission',
    dePermission: 'Delete Permission',
    updatePermissionGroup: 'Update Permission Group',
    dePermissionGroup: 'Delete Permission Group',
    menu: 'Menu',
    addMenu: 'Add Menu',
    userManger: 'User',
    deMenus: 'Delete Menu',
    updateMenus: 'Update Menu',
    addAgent: 'Add Agent',
    updateAgent: 'Update Agent',
    addchildren: 'Add children',
    changePoint: 'Agent plus and minus points',
    agentLogger: 'Agent log',
    agentPermissions: 'Agent Permission Assignment',
    agentChildrenAccount: 'Agent Sub-Account',
    addUser: 'Add member',
    updateUser: 'Update Member',
    userChangePoint: 'Member plus or minus points',
    userLogger: 'Member Log',
    userLimitTable: 'Member limit table login',
    userGame: 'Member Game Record',
    gameList: 'Game Record',
    tuserLine: 'Kick out of game',
    tuserRoom: 'Kick out of the room',
    tuserIp: 'Ban IP',
    gameReport: 'Game Report',
    exportAgent: 'Export agent data report',
    exportUser: 'Export member data report',
    addPoint: 'Root add point',
    loggerManager: 'Log',
    menuBanks: 'Up and down management',
    banks: 'Shangfen Account',
    deposit: 'Advanced points management',
    withdraw: 'sub-management',
    addbanks: 'Add upper-point account',
    addWtthdraw: 'Add customer score information',
    updateWith: 'Risk control audit customer score information',
    withPrint: 'Print customer score information',
    withPoint: 'Customers place points and confirm payment',
    addDeposit: 'Add customer points information',
    updateDeposit: 'Review customer points information',
    onlineUser: 'Online user information',
    join: 'Franchise Information',
    notice: 'Announcement',
    updateNotice: 'Update Announcement',
    deNotice: 'Delete Announcement',
    statistics: 'Report Settlement',
    pointlist: 'Point List',
    rootUpdate: 'Total Account Configuration',
    agentStatus: 'Agent Status',
    userStatus: 'User status modification',
    ipLimits: 'Remove IP restrictions',
    gameManager: 'Game',
    gamePlay: 'Game Play',
    agentTables: 'Agent Tables',
    settleOrder: 'Unsettle Orders',
    playSet: 'Website Settings',
    userPolicies: 'User Policies',
    joinUs: 'Join Us'
  },
  statistics: {
    startSet: 'Settlement Submission',
    auditSet: 'Settlement Audit',
    endSet: 'Add points to settlement',
    firstWeek: 'One week ahead',
    oneWeek: 'Latest week',
    LastWeek: 'The next week',
    agentName: 'Username',
    staStatus: 'Settlement Status',
    print: 'Print',
    firstMonth: 'One month up',
    oneMonth: 'Last January',
    LastMonth: 'One month in the future',
    weekly: 'Weekly',
    monthly: 'Monthly',
    totalWinLose: 'Total win/lose',
    totalrealWinLose: 'Total actual win/lose',
    totalRollingAmount: 'Total rolling amount',
    totalRollingComm: 'Total rolling commission',
    totalRollingPayable: 'Total rolling amount payable',
    totalSharePayable: 'Total share payable',
    totalRebatesPayable: 'Total rebates payable',
    totalComm: 'Total commission',
    totalCountPoint: 'Total count point',
    totalbetAmount: 'Total bet amount',
    agentWeek: 'Agent Week',
    agentMonth: 'Agent Month',
    userWeek: 'User Week',
    userMonth: 'User Month',
    submitted: 'Submitted',
    pending: 'Pending review',
    added: 'Added',
    settled: 'Settled'
  },
  playset: {
    status: 'Status',
    type: 'Type',
    first: 'First-level menu',
    second: 'Home menu',
    last: 'Submenu',
    shoeNo: 'Shoe No',
    tableNo: 'Table No'
  },
  betType: {
    bacc: {
      banker: 'Banker',
      tie: 'Tie',
      player: 'Player',
      small: 'Small',
      big: 'Big',
      banker_pair: 'Banker Pair',
      player_pair: 'Player Pair'
    },
    dt: {
      drangon: 'Dragon',
      tie: 'Tie',
      tiger: 'Tiger'
    },
    bpNiuniu: {
      bankerniu: 'Banker Bull',
      playerniu: 'Player Bull',
      bull: 'Bull',
      doubleniu: 'Double Bull Bull',
      special: 'special',
      pDouble: 'Player Double',
      bDouble: 'Banker Double'
    },
    niuNiu: {
      player1: 'Player 1 doubled',
      palyer1db: 'Player 1 doubled',
      p1Many: 'Player1 Many',
      player2: 'Player 2 doubled',
      palyer2db: 'Player 2 doubled',
      p2Many: 'Player2 Many',
      player3: 'Player 3 doubled',
      palyer3db: 'Player doubled',
      p3Many: 'Player3 Many',
      player5: 'Player 5 is doubled',
      palyer5db: 'Player 5 doubled'
    },
    tongziRes: {
      playerwin1: 'Player wins 1x',
      playerwin2: 'Player wins 2x',
      playerwin3: 'Player wins 3x',
      bankerwin1: 'Banker wins 1x',
      bankerwin2: 'Banker wins 2x',
      bankerwin3: 'Banker wins 3x',
      tiewin: 'tie'
    },
    bullRes: {
      nobull: 'no bull',
      bull: 'bull',
      bullBull: 'bull bull',
      wuhua: 'WUHUA',
      wuxiao: 'WUXIAO',
      zhadan: 'ZHADAN'
    },
    bpTongzi: {
      banker: 'banker',
      player: 'Player',
      tie: 'Tie',
      pair: 'Single Pair',
      doublepair: 'Double Pair'
    }
  }
};