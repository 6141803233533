export default {
  meta: {
    title: {
      dashboard: '个人中心',
      system: '系统管理',
      role: '角色管理',
      permission: '权限管理',
      webSetting: '网站管理',
      notice: '公告管理',
      userManager: '用户管理',
      onlineUser: '在线用户',
      game: '游戏管理',
      betOrders: '游戏记录',
      roundInfos: '局信息',
      unSettleOrder: '未结算订单',
      report: '游戏报表',
      loggerManager: '日志管理',
      riskManager: '风控管理',
      rootRecharge: '总账户加点',
      rootUpdate: '总账户配置',
      blockIps: 'ip配置',
      userPolicies: '用户策略',
      trade: '上下分管理',
      rechargeAccount: '上分账号管理',
      deposit: '上分管理',
      withdraw: '下分管理',
      transaction: '上下分统计',
      joinUs: '加盟信息',
      reportStats: '报表结算'
    }
  },
  common: {
    role: '角色',
    login: '登陆',
    reset: '重置',
    logout: '登出',
    dragonTiger: '龙虎',
    baccarat: '百家乐',
    title: '标题',
    desc: '描述',
    status: '状态',
    currency: '币种',
    user: '用户',
    agent: '代理',
    member: '成员',
    subAccount: '子帐户',
    open: '开启',
    close: '关闭',
    all: '全部',
    bet: '投注',
    permission: '权限',
    account: '账号',
    childrenAccount: '子账号',
    logger: '日志',
    createdTime: '创建时间',
    gameRecord: '游戏记录',
    type: '类型',
    remark: '备注',
    startTime: '起始时间',
    endTime: '结束时间',
    clearCondition: '清空条件',
    fuzzy: '模糊查找',
    exactly: '精确查找',
    lastweek: '上周',
    yesterday: '昨天',
    today: '今天',
    thisweek: '本周',
    thismonth: '本月',
    keyword: '关键字',
    detail: '明细',
    tables: '限制桌登入',
    bpNiuniu: '庄闲牛牛',
    niuNiu: '牛牛',
    bpTongzi: '庄闲筒子',
    tongzi: '筒子',
    upPassWord: '您的密码已使用超过一个月,请立即进行修改',
    name: '名称',
    email: '邮箱',
    search: '查询',
    createdBy: '创建人',
    createdAt: '创建时间',
    updatedAt: '更新时间',
    actions: '操作',
    edit: '编辑 ',
    delete: '删除',
    add: '添加 ',
    username: '用户名',
    password: '密码',
    captcha: '验证码',
    ruleMsg: {
      require: '不能为空!',
      validatePassword: '请输入密码',
      passwordPattern: '密码不能少于6个字符，包含大写字母、小写字母、数字和特殊符号。',
      confirmPassword: '密码不匹配！'
    },
    oldPassword: '旧密码',
    confirmPassword: '确认密码',
    confirm: '确认',
    cancel: '取消',
    success: '成功',
    createSuccess: '创建成功',
    editSuccess: '编辑成功',
    deleteSuccess: '删除成功',
    description: '描述',
    guardName: 'Guard name',
    permissionGroup: '权限组',
    displayName: '显示名称',
    icon: 'Icon',
    sequence: '排序',
    level: '层级',
    router: '路由',
    parentMenu: '父级菜单',
    topMenu: '顶级菜单',
    assignPermission: '分配权限',
    assignRole: '分配角色',
    selectAll: '全选',
    unselectAll: '全不选',
    changePassword: '修改密码',
    googleVerify: '谷歌验证',
    sponsorLinks: '赞助商链接',
    loginInfo: '登录信息',
    currentLogUser: '当前登录的用户',
    alias: '别名',
    lastLoginInfo: '上次登录信息',
    lastLoginIp: '上次登录IP',
    loginInfoTime: '本次登录信息',
    nowLoginTime: '登录时间',
    loginTimes: '登录次数',
    currentLastnews: '当前最新新闻',
    twoFaMobileDesc: '打开您的2FA移动应用程序并扫描左侧的二维码',
    twoFaMobilesecDesc: '如果您的 2FA 移动应用程序不支持 QR 条形码，请输入以下数字：',
    inputVerifyTip: '请输入验证器上最新的六位数字',
    currencyTip: '*货币类型一旦设置无法修改，而且所有下级也按此货币类型结算，只有公司级别才有权修改货币类型',
    upperLowerPoint: '正常上下点',
    divideToProportion: '按照比例划分',
    rebateTip: '*返利比例设置，需输赢+洗码的总金额为',
    rebateTip2: '边计算',
    gameType: '游戏类型',
    autoRefresh: '自动刷新',
    totalMoney: '总金额',
    depMoney: '存钱',
    withMoney: '提款',
    copy: '复制',
    download: '下载',
    last8Days: '最近8天',
    yes: '是',
    no: '否',
    menu: '菜单',
    path: '路径',
    selectIconTip: '点击选择图标',
    refresh: '刷新',
    sort: '排序',
    delMessage: '确定删除这条数据吗？'
  },
  role: {
    name: '角色名称',
    guard: '守卫',
    parentRole: '父级角色',
    assignPermission: '分配权限',
    ruleMsg: {
      lengthName: '名称必须介于2到32个字符之间',
      namePattern: '名称只能包含字母、数字和_-。'
    }
  },
  permission: {
    title: '权限',
    menuType: '菜单类型',
    module: '模块',
    menu: '菜单',
    button: '按钮',
    icon: '图标',
    selectIconTip: '点击选择图标',
    isLink: '外链',
    visible: '隐藏',
    menuTitle: '菜单名称',
    permission: '权限标志',
    path: '路径',
    componentPath: '组件路径',
    cache: '缓存',
    routeName: '路由名',
    superCategory: '上级菜单',
    buttonName: '按钮名称',
    redirect: '重定向',
    breadcrumb: '面包屑',
    affix: '附加',
    isCredit: '附加',
    ruleMsg: {
      title: '请输入菜单名称或操作名称!'
    }
  },
  onlineUser: {
    onlineUser: '在线用户',
    username: '用户名',
    balance: '实时余额',
    lastBet: '未结算注单',
    tableId: '当前所在桌号',
    winLossState: '连输连赢',
    tuserLine: '强制下线',
    tuserRoom: '踢出房间',
    tuserIp: '禁止IP',
    fenghao: '封号',
    loginMethod: '登录方式'
  },
  record: {
    username: '用户名',
    gameId: '局编号',
    tableNo: '桌号',
    betTime: '下注时间',
    settleTime: '结算时间',
    betDetails: '下注详情',
    gameResult: '开奖结果',
    betAmount: '下注金额',
    validBet: '洗码量',
    commission: '代理抽水',
    winLose: '输赢',
    balance: '余额',
    platform: '平台',
    status: '状态',
    notSettle: '未结算',
    settled: '已结算',
    cancelled: '已取消',
    tableShoes: '桌号-靴号',
    limit: '限制',
    totalWinlose: '总赢输'
  },
  report: {
    username: '用户名',
    betweenTime: '时间',
    nickname: '别名',
    betCount: '投注次数',
    betAmount: '投注金额',
    winLose: '输赢金额',
    rolling: '洗码量',
    rollingRate: '洗码(%)',
    rollingAmount: '洗码佣金',
    commissionAmount: '抽成佣金',
    costRate: '占成(%)',
    upperProfit: '缴上家利润',
    lowerProfit: '应付占成',
    addPoint: '上分',
    reducePoint: '下分',
    balance: '余额',
    detail: '明细',
    agentName: '上级代理',
    memberName: '会员用户名',
    backRate: '返点(%)',
    backPoint: '应付返点',
    countRolling: '应付洗码',
    countPoint: '下家利润',
    realWinLos: '实际输赢',
    agentComm: '代理抽水',
    uCountPoint: '应付合计',
    back: '返回上一级',
    agentUser: '代理账户',
    totalReport: '总报告',
    addAndReduce: '加/减'
  },
  agent: {
    upperAgent: '上级代理',
    agentInfo: '代理信息',
    sn: '用户名',
    nickname: '昵称',
    status: '状态',
    phoneNumber: '电话',
    point: '真人点数',
    sharing: '真人占成',
    commission: '真人洗码',
    secret: 'api秘钥',
    sign_key: '数据签名',
    addChildren: '添加下级',
    betLimit: '下注限制',
    totalPoint: '剩余点数',
    addchildren: '添加下级',
    changePoint: '加减点',
    todayLimit: '当日赢额限制',
    countLimit: '总赢额限制',
    back_rate: '返点比',
    settleType: '结算周期',
    back_limit: '返点限制',
    agentRelation: '代理关系',
    commType: '洗码方式',
    agentList: '代理列表',
    memberList: '会员列表',
    subAccountList: '子账户列表',
    comm1: '全部',
    comm2: '输',
    comm3: '赢',
    registered: '已注册',
    ruleMsg: {
      require: '不能为空!',
      validateSn: '必须以字母开头，以及 0-9 和 a-z',
      validateConfirmPw: '请输入密码',
      confirmPassword: '密码不匹配！',
      validatePoint: '点数不能大于',
      validateCostRate: '成本率不能大于',
      validateRebateRate: '返利率不能大于',
      validateBetLimit: '至少 1 组代理',
      validateRebateRatio: '返利比例不能大于',
      validateChangePoint: '点数必须大于0',
      checkPoint: '点数不能大于上级',
      checkPoint2: '当前用户积分不足，无法扣除'
    }
  },
  user: {
    username: '用户名',
    nickname: '昵称',
    last_login_time: '最后登录时间',
    today_money_count: '今日',
    bet_status: '投注状态',
    loginRemind: '上线提醒',
    limitRegion: '限制地区',
    register_time: '创建时间',
    hideRebate: '隐藏洗码',
    baccCommission: '百家乐抽水',
    winLimit: '赢额限制',
    dtCommission: '龙虎抽水',
    userType: '用户来源',
    firstUser: '主网',
    secUser: '现金网',
    thrUser: 'RUPT',
    reUser: '解除限制',
    loginIp: '登录IP',
    baccCommBanker: '系统默认(庄赢:公司抽水5%)',
    baccCommBanker2: '庄赢:抽水10% = 代理抽水5% + 公司抽水5%',
    baccCommTie: '和赢:抽水金额 = 下注金额',
    baccCommPair: '对子:抽水金额 = 下注金额',
    dtComm: '系统默认(不抽水,和局通杀)',
    dtComm2: '龙或者虎时:公司抽水3%,和局返还本金',
    dtComm3: '龙或者虎时:代理抽水2% + 公司抽水3%,和局返还本金',
    dtComm4: '龙或者虎时:代理抽水5%,和局通杀',
    dtComm5: '龙或者虎时:代理抽水5% + 公司抽水3%,和局返还本金',
    todayLimit: '当日赢额限制选填,不填写,为0则没有限制',
    countLimit: '总赢额限制选填,不填写,为0则没有限制'
  },
  editPoint: {
    addOrReduce: '加点或减点',
    add: '加点(+)',
    reduce: '减点(-)',
    changePoint: '加减点数',
    upperAgent: '从哪一级过账到此账号？'
  },
  logger: {
    eventGroup: '事件组',
    event: '事件',
    username: '用户名',
    operator: '操作人',
    area: '地区',
    operationContent: '操作内容',
    changePoint: '金额',
    balance: '余额',
    noLimit: '无限制',
    loginLogout: '登录退出',
    agentLogin: '代理登录',
    userLogin: '用户登录',
    logout: '登出',
    lock: '锁定',
    unlock: '解锁',
    enableBet: '打开下注',
    disableBet: '禁用下注',
    balanceChange: '账户余额变化',
    addDot: '添加点',
    rootAccplusPoint: '根账户加分',
    minusPoint: '减分',
    bet: '赌注',
    betToWin: '投注获胜',
    addPointToTop: '将点添加到顶部',
    minusLowerPoint: '较低点减去点',
    userSettlePlusPoint: '用户结算加积分',
    agencySettlePlusPoint: '代理结算加积分',
    groupOp: '用户组操作',
    createUserAgent: '创建用户或代理',
    editUser: '编辑用户',
    editAgent: '编辑代理',
    modifyRootAcc: '修改root帐户',
    addSubPoint: '加减点'
  },
  super: {
    event: '超级用户加点',
    addPoint: '增加点数',
    addRemark: '备注',
    tableNo: '表号',
    amount: '金额',
    round: '圆形的',
    max_counts: '最大计数',
    now_counts: '现在计数',
    his_counts: '历史很重要',
    ip: 'IP地址'
  },
  currency: {
    rate: '汇率'
  },
  bank: {
    accountNumber: '卡号',
    accountType: '银行',
    accountName: '支行名称',
    userName: '姓名',
    status: '状态',
    member: '用户名',
    money: '交易金额',
    transfer: '交易号',
    transferTime: '交易时间',
    setTime: '审核时间',
    dBankInfo: '存入账户',
    WbKInfo: '提现账户',
    name: '汇款人姓名',
    remark: '备注信息',
    phone: '手机号码',
    wthMoney: '交易金额',
    isTure: '操作',
    sussess: '通过',
    failed: '不通过',
    wait: '待审核',
    confirmTrue: '确认',
    cancel: '取消',
    info: '核对信息无误后请点击确认!',
    deposit: '已入账',
    withdraw: '已打款',
    othername: '上级代理别名',
    opperson: '录入员',
    auperson: '审核员',
    endperson: '打款员',
    sussessAdd: '操作人信息',
    endtime: '打款时间',
    end: '打款',
    waitau: '审核',
    print: '打印',
    settype: '上分类型',
    setMoney: '汇款',
    setPoint: '签洗码',
    depMoney: '上分点数',
    withMoney: '下分点数',
    moneyPoint: '汇款金额',
    waitPoint: '待还单',
    letPoint: '已还单',
    withSettype: '下分类型',
    sendMoney: '打款',
    sendPoint: '还洗码',
    desPoint: '签单',
    setPointName: '还单',
    signPoint: '签单',
    transferPoint: '结算',
    firstOp: '点击确认',
    upperLowerType: '上下类型',
    totalSuccessMoney: '总成功钱',
    totalWaitMoney: '总等待钱',
    totalFailMoney: '总失败资金',
    totalRemittance: 'Total Remittance',
    ruleMsg: {
      require: '不能为空!',
      checkSn: '用户名错误，用户不存在',
      validateUsername: '必须以字母开头，以及 0-9 和 a-z'
    },
    WbKInfoTip: '*(开户银行,户名,卡号 以,隔开)请按此格式填写.',
    success: '成功',
    failure: '失败',
    pendingOrder: '待还单',
    remittance: '汇款',
    signCode: '签洗码',
    signOrder: '签单',
    settlement: '结算',
    payment: '打款',
    returnSignRolling: '还洗码',
    returnSignOrder: '还签单',
    approved: '审核通过',
    returnOrder: '已打款 (已还单)'
  },
  join: {
    name: '称呼',
    phone: '手机',
    email: '邮箱',
    skype: '其他通讯如:skype',
    content: '留言内容',
    status: '信息状态',
    beContacted: 'To be contacted',
    contacted: 'Contacted'
  },
  notice: {
    notice_name: '公告名称',
    op_name: '公告添加人',
    notice_type: '公告类型',
    content: '公告内容',
    status: '公告状态',
    agent: '代理公告',
    user: '会员公告',
    ruleMsg: {
      require: '不能为空!'
    }
  },
  auth: {
    notice: '公告管理',
    join: '加盟信息',
    banks: '存取款管理',
    userliston: '在线用户操作',
    online: '在线用户',
    bill_index: '游戏记录',
    agent_report: '代理报表',
    user_report: '用户报表'
  },
  sys: {
    updateRole: 'Update Role',
    deRole: 'Delete Role',
    permission: '权限管理',
    addPermissions: '添加权限',
    manager: '系统管理',
    permissionGroup: '权限组管理',
    updatePermission: 'Update Permission',
    dePermission: 'Delete Permission',
    updatePermissionGroup: 'Update Permission Group',
    dePermissionGroup: 'Delete Permission Group',
    menu: '菜单管理',
    addMenu: '添加菜单',
    userManger: '用户管理',
    deMenus: '删除菜单',
    updateMenus: '编辑菜单',
    addAgent: '添加代理',
    updateAgent: '修改代理',
    addchildren: '添加下级',
    changePoint: '代理加减点',
    agentLogger: '代理日志',
    agentPermissions: '代理权限分配',
    agentChildrenAccount: '代理子账号',
    addUser: '添加会员',
    updateUser: '修改会员',
    userChangePoint: '会员加减点',
    userLogger: '会员日志',
    userLimitTable: '会员限制桌登录',
    userGame: '会员游戏记录',
    gameList: '游戏记录',
    tuserLine: '踢出游戏',
    tuserRoom: '踢出房间',
    tuserIp: '禁止IP',
    gameReport: '游戏报表',
    exportAgent: '导出代理数据报表',
    exportUser: '导出会员数据报表',
    addPoint: 'root加点',
    loggerManager: '日志管理',
    menuBanks: '上下分管理',
    banks: '上分账号管理',
    deposit: '上分管理',
    withdraw: '下分管理',
    addbanks: '添加上分账户',
    addWtthdraw: '添加客户下分信息',
    updateWith: '风控审核客户下分信息',
    withPrint: '打印客户下分信息',
    withPoint: '客户下分确认打款',
    addDeposit: '添加客户上分信息',
    updateDeposit: '审核客户上分信息',
    onlineUser: '在线用户信息',
    join: '加盟信息管理',
    notice: '公告信息管理',
    updateNotice: 'Update Announcement',
    deNotice: 'Delete Announcement',
    statistics: '报表结算',
    pointlist: '上下分统计',
    rootUpdate: '总账户配置',
    agentStatus: '代理状态修改',
    userStatus: '用户状态修改',
    ipLimits: '解除IP限制',
    gameManager: '游戏管理',
    gamePlay: '开局信息',
    agentTables: '包网设置',
    settlOrder: '游戏结算',
    playSet: '网站设置',
    userPolicies: '用户策略'
  },
  statistics: {
    startSet: '结算提交',
    auditSet: '结算审核',
    endSet: '结算加点',
    firstWeek: '往上一周',
    oneWeek: '最新一周',
    LastWeek: '往后一周',
    agentName: '用户名',
    staStatus: '结算状态',
    print: '打印',
    firstMonth: '往上一月',
    oneMonth: '最近一月',
    LastMonth: '往后一月',
    weekly: '每周',
    monthly: '每月',
    totalWinLose: '总赢/输',
    totalrealWinLose: '实际赢/输总数',
    totalRollingAmount: '总滚动量',
    totalRollingComm: '总滚动佣金',
    totalRollingPayable: '滚动应付总额',
    totalSharePayable: '应付股份总额',
    totalRebatesPayable: '应付回扣总额',
    totalComm: '总佣金',
    totalCountPoint: '总计数点',
    totalbetAmount: '总投注金额',
    agentWeek: '代理周',
    agentMonth: '代理月份',
    userWeek: '用户周',
    userMonth: '用户月份',
    submitted: 'Submitted',
    pending: 'Pending review',
    added: 'Added',
    settled: 'Settled'
  },
  playset: {
    status: '状态',
    type: '类型',
    first: '一级菜单',
    second: '首页菜单',
    last: '子菜单',
    shoeNo: '靴号',
    tableNo: '桌号'
  },
  betType: {
    bacc: {
      banker: '庄',
      tie: '和',
      player: '闲',
      small: '小',
      big: '大',
      banker_pair: '庄对',
      player_pair: '闲对'
    },
    dt: {
      drangon: '龙',
      tie: '和',
      tiger: '虎'
    },
    bpNiuniu: {
      bankerniu: '庄牛',
      playerniu: '闲牛',
      bull: '单牛牛',
      doubleniu: '双牛牛',
      special: '五花五小四炸',
      pDouble: '闲翻倍',
      bDouble: '庄翻倍'
    },
    niuNiu: {
      player1: '闲1平倍',
      palyer1db: '闲1翻倍',
      p1Many: '闲1多倍',
      player2: '闲2平倍',
      palyer2db: '闲2翻倍',
      p2Many: '闲2多倍',
      player3: '闲3平倍',
      palyer3db: '闲3翻倍',
      p3Many: '闲3多倍',
      player5: '闲5平倍',
      palyer5db: '闲5翻倍'
    },
    tongziRes: {
      playerwin1: '闲赢平倍',
      playerwin2: '闲赢翻2倍',
      playerwin3: '闲赢翻3倍',
      bankerwin1: '庄赢平倍',
      bankerwin2: '庄赢翻2倍',
      bankerwin3: '庄赢翻3倍',
      tiewin: '和'
    },
    bullRes: {
      nobull: '无牛',
      bull: '牛',
      bullBull: '牛牛',
      wuhua: '五花',
      wuxiao: '五小',
      zhadan: '炸弹'
    },
    bpTongzi: {
      banker: '庄',
      player: '闲',
      tie: '和',
      pair: '单对子',
      doublepair: '双对子'
    }
  }
};