import http from '../libs/http';
export var login = function login(_ref) {
  var username = _ref.username,
    password = _ref.password,
    captcha = _ref.captcha,
    ckey = _ref.ckey,
    real_ip = _ref.real_ip;
  return http.post('/api/login', {
    sn: username,
    password: password,
    captcha: captcha,
    real_ip: real_ip,
    ckey: ckey
  });
};
export var verify2Fa = function verify2Fa(data) {
  return http.post('/api/verify2Fa', data);
};
export var getCaptcha = function getCaptcha() {
  return http.get('/api/captcha');
};
export var getLoginUser = function getLoginUser() {
  return http.get('/api/login-user');
};
export var getUserPermission = function getUserPermission() {
  return http.get('/api/permission-user-all');
};
export var generateTwoFactor = function generateTwoFactor(params) {
  return http.get("agent-api/login-user/generateTwoFactor", params);
};
export var enableTwoFactor = function enableTwoFactor(params) {
  return http.post("agent-api/login-user/enableTwoFactor", params);
};
export var disableTwoFactor = function disableTwoFactor(params) {
  return http.post("agent-api/login-user/disableTwoFactor", params);
};
export var logout = function logout() {};